import React from "react";
import facepaint from "facepaint";

const breakpoints = [991];

export const mq = facepaint(
  breakpoints.map((bp) => `@media (min-width: ${bp}px)`)
);

const checkIfIsMobile = (size) => {
  return size < 991;
};

export const useMobile = () => {
  const [isMobile, setMobile] = React.useState(
    checkIfIsMobile(window.innerWidth)
  );

  React.useEffect(() => {
    const onResize = () => {
      const result = checkIfIsMobile(window.innerWidth);
      if (result !== isMobile) {
        setMobile(result);
      }
    };
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, [isMobile, setMobile]);

  return { isMobile };
};

export default mq;
