import React from "react";
import Signup from "./pages/signup";
import Subscribe from "./pages/subscribe";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Switch, Route, Redirect } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import AffiliateDashboard from "./pages/affiliate-dashboard";
import ThankYou from "./pages/thanks";
import Login from "./pages/login";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export const AuthCtx = React.createContext({});

const Affiliates = () => {
  const [authToken, setAuthToken] = React.useState(
    localStorage.authToken || null
  );

  React.useEffect(() => {
    if (authToken && localStorage.authToken !== authToken) {
      localStorage.setItem("authToken", authToken);
    } else if (!authToken) {
      localStorage.removeItem("authToken");
    }
  }, [authToken]);

  const authCtx = React.useMemo(() => {
    return {
      authToken,
      login: (token) => {
        setAuthToken(token);
      },
      logout: () => {
        setAuthToken(null);
      },
    };
  }, [authToken, setAuthToken]);

  let routes;

  if (authToken) {
    routes = (
      <Switch>
        <Route exact path="/affiliates" component={AffiliateDashboard} />
        <Redirect to="/affiliates" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/affiliates/login" component={Login} />
        <Redirect to="/affiliates/login" />
      </Switch>
    );
  }

  return <AuthCtx.Provider value={authCtx}>{routes}</AuthCtx.Provider>;
};

function App() {
  return (
    <div className="App">
      <Elements stripe={stripePromise}>
        <Router>
          <Switch>
            <Route path="/signup" component={Signup} />
            <Route path="/affiliates" component={Affiliates} />
            <Route path="/subscribe" component={Subscribe} />
            <Route path="/thank-you" component={ThankYou} />
            <Redirect to="/signup" />
          </Switch>
        </Router>
      </Elements>
    </div>
  );
}

export default App;
