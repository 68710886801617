/** @jsx jsx */
import Color from "color";
import { jsx } from "@emotion/core";

export const Field = ({ label, children }) => {
  return (
    <div
      css={{
        marginBottom: "1.5rem",
      }}
    >
      <label
        css={{
          display: "block",
          fontWeight: 600,
          fontSize: 16,
          lineHeight: "24px",
          marginBottom: "0.25rem",
        }}
      >
        {label}
      </label>
      {children}
    </div>
  );
};

export const Input = (props) => {
  return (
    <input
      css={{
        width: "100%",
        height: "2.5rem",
        backgroundColor: "#F1F3F5",
        border: "transparent",
        borderRadius: 4,
        transitionDuration: "200ms",
        padding: "0px 0.5rem",
        fontSize: 16,
        boxSizing: "border-box",
        lineHeight: "24px",
        "&:placeholder": {
          color: "#8593A5",
        },
        "&:focus": {
          outline: "none",
          border: "solid 1px #5435C8",
          boxShadow: `0 0 0 0.2em ${Color("#5435C8").alpha(0.2).toString()}`,
        },
      }}
      {...props}
    />
  );
};

export const Button = (props) => {
  return (
    <button
      css={{
        height: 40,
        cursor: "pointer",
        borderRadius: 5,
        border: "transparent",
        padding: "0rem 1rem",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 600,
        transitionDuration: "200ms",
        "&:focus": {
          outline: "none",
        },
      }}
      {...props}
    >
      {props.children}
    </button>
  );
};

export const PrimaryButton = ({ children, ...restProps }) => {
  return (
    <Button
      css={{
        backgroundColor: "#2439A3",
        color: "white",
        "&:hover": {
          backgroundColor: Color("#2439A3").lighten(0.2).toString(),
        },
        "&:active": {
          backgroundColor: Color("#2439A3").darken(0.2).toString(),
        },
        "&:disabled": {
          backgroundColor: Color("#2439A3").lighten(0.2).toString(),
        },
      }}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default { Input };
