/** @jsx jsx */
import qs from "query-string";
import Color from "color";
import { jsx } from "@emotion/core";
import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { PrimaryButton } from "../components/forms";
import api from "../api";
import spbLogo from "../assets/spb-logo.png";
import pinkCheck from "../assets/pink-check.svg";
import { mq, useMobile } from "../components/responsive";

const Feature = ({ desc }) => {
  return (
    <div css={{ marginBottom: "0.5rem", display: "flex" }}>
      <img css={{ width: 20 }} src={pinkCheck} alt="pink check" />
      <div
        css={{
          fontSize: "18px",
          lineHeight: "26px",
          marginLeft: "8px",
        }}
      >
        {desc}
      </div>
    </div>
  );
};

const Plan = ({ name, isActive, price, cycle, onSelect }) => {
  return (
    <div
      onClick={onSelect}
      css={{
        cursor: "pointer",
        flex: 1,
        marginRight: "1rem",
        userSelect: "none",
        marginBottom: "2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        borderRadius: 5,
        padding: "1rem",
        textAlign: "center",
        boxShadow:
          "0px 16px 24px rgba(29, 29, 53, 0.1), 0px 0px 1px rgba(29, 29, 53, 0.1)",
        ...(isActive
          ? {
              border: "solid 1px #EA355C",
            }
          : {
              border: "solid 1px #F1F3F5",
            }),

        "&:last-child": {
          marginBottom: "0rem",
          marginRight: "0rem",
        },
      }}
    >
      <div>
        <div
          css={{
            textTransform: "uppercase",
            flex: 1,
            fontSize: 16,
            color: "#EA355C",
            lineHeight: "24px",
            fontWeight: 600,
          }}
        >
          {name}
        </div>

        <div css={{ flex: 1 }}>
          <span css={{ fontWeight: 600, fontSize: 32, lineHeight: "40px" }}>
            ${price}
          </span>
          <span css={{ color: "#8593A5" }}>{cycle}</span>
        </div>

        <div css={{ paddingTop: "1rem" }}>
          <Feature desc="Auto Friend Adder" />
          <Feature desc="Auto Welcomer" />
          <Feature desc="In-Facebook CRM with Pipeline" />
          {name === "Monthly" ? (
            <Feature desc="20% Affiliate Commission" />
          ) : (
            <Feature desc="50% Affiliate Commission" />
          )}
        </div>
      </div>
    </div>
  );
};

const Subscribe = ({ history, location }) => {
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [cardElementFocused, setCardElementFocused] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const { isMobile } = useMobile();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(null);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      setLoading(false);
      return setErrorMessage(error.message);
    }

    try {
      await api.post(
        "/subscribe",
        {
          token: paymentMethod.id,
          kind: selectedPlan,
        },
        {
          headers: {
            Authorization: `Token ${qs.parse(location.search).token}`,
          },
        }
      );
      window.fbq("track", "AddPaymentInfo");
      history.push("/thank-you");
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div
      css={{
        maxWidth: 800,
        margin: "0 auto",
        paddingTop: "3rem",
      }}
    >
      <div
        css={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <img
          src={spbLogo}
          alt="social profit bots"
          css={{ height: 80, marginBottom: "1.5rem" }}
        />

        <div
          css={{
            fontSize: 24,
            lineHeight: "32px",
            fontWeight: "600",
            marginBottom: "1rem",
          }}
        >
          Choose a plan
        </div>

        <div
          css={{
            color: "#66778A",
            textAlign: "center",
          }}
        >
          Both plans include a <strong>7 days free trial</strong> -{" "}
          <div css={mq({ display: ["block", "inline "] })}>
            You won't be charged today.
          </div>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        css={{ marginTop: "3rem", paddingBottom: "2rem" }}
      >
        {(!isMobile || selectedPlan === null) && (
          <div
            css={mq({
              display: "flex",
              flexDirection: ["column", "row"],
              alignItems: ["center", "flex-start"],
            })}
          >
            <Plan
              name="Lifetime"
              isActive={selectedPlan === "lifetime"}
              price="497"
              cycle=" one time"
              onSelect={() => setSelectedPlan("lifetime")}
            />
            <Plan
              name="Monthly"
              isActive={selectedPlan === "monthly"}
              price="67"
              cycle="/month"
              onSelect={() => setSelectedPlan("monthly")}
            />
          </div>
        )}

        {isMobile && selectedPlan !== null && (
          <div
            css={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              css={{
                color: "#EA355C",
                textTransform: "uppercase",
                fontWeight: "600",
              }}
            >
              {selectedPlan === "lifetime" ? "lifetime" : "monthly"}
            </div>

            <div
              css={{ fontWeight: "600", fontSize: "32px", lineHeight: "40px" }}
            >
              {selectedPlan === "lifetime" ? "$497" : "$67"}
            </div>
          </div>
        )}

        {selectedPlan && (
          <div css={{ maxWidth: 400, margin: "0 auto" }}>
            <div css={{ marginTop: "2rem" }}>
              <strong>Enter your card details</strong>
            </div>
            <div
              css={{
                marginTop: "2rem",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "2.5rem",
                backgroundColor: "white",
                border: "solid 1px #F1F3F5",
                borderRadius: 4,
                transitionDuration: "200ms",
                padding: "0px 0.5rem",
                fontSize: 16,
                boxSizing: "border-box",
                lineHeight: "24px",
                ...(cardElementFocused
                  ? {
                      outline: "none",
                      border: "solid 1px #5435C8",
                      boxShadow: `0 0 0 0.2em ${Color("#5435C8")
                        .alpha(0.2)
                        .toString()}`,
                    }
                  : {}),
              }}
            >
              <div css={{ flex: 1 }}>
                <CardElement
                  onFocus={() => setCardElementFocused(true)}
                  onBlur={() => setCardElementFocused(false)}
                />
              </div>
            </div>

            {errorMessage && (
              <div css={{ color: "#C70000", marginTop: "1rem" }}>
                {errorMessage}
              </div>
            )}
            <div
              css={{
                marginTop: "2rem",
              }}
            >
              <PrimaryButton
                disabled={loading}
                type="submit"
                style={{ width: "100%" }}
              >
                {loading ? "Subscribing..." : "Subscribe"}
              </PrimaryButton>

              {isMobile && selectedPlan !== null && (
                <div
                  css={{
                    fontSize: "18px",
                    lineHeight: "26px",
                    marginTop: "1rem",
                    fontWeight: "600",
                    color: "#2439A3",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedPlan(null)}
                >
                  Pick another plan
                </div>
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default Subscribe;
