import React from "react";
import moment from "moment";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import api from "../api";
import { AuthCtx } from "../App";

const Wrapper = styled.div`
  max-width: 561px;
  margin: 0 auto;
  padding-top: 4.5rem;
`;

const Table = styled.table`
  width: 100%;

  th {
    border-bottom: 1px solid #f1f3f5;
    padding: 1rem 0rem;
    color: #8593a5;
    font-weight: 600;
    text-align: left;
  }

  td {
  }
`;

const Td = styled.td`
  color: #1d1d35;
  font-size: 16px;
  line-height: 24px;
  padding: 1rem 0rem;
  font-weight: 500;

  &.strong {
    font-weight: 600;
  }
`;

const StatusBadge = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: rgba(36, 57, 163, 0.08);
  display: inline-block;
  color: #2439a3;
  font-weight: 600;

  ${(props) =>
    props.inactive &&
    css`
      background-color: #f1f3f5;
      color: #8593a5;
    `}
`;

const Stats = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 72px;
`;

const Stat = styled.div`
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(212, 218, 227, 0.56);
  width: 216px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0rem 28px;
  flex-direction: column;

  .label {
    color: #8593a5;
    font-weight: 600;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 24px;
  }

  .value {
    font-weight: 600;
    color: #2439a3;
    font-size: 34px;
  }
`;

const CopyUrl = styled.div`
  display: flex;
  min-width: 400px;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 40px;

  .url {
    border-top: 1px solid #d4dae3;
    white-space: nowrap;
    border-bottom: 1px solid #d4dae3;
    border-left: 1px solid #d4dae3;
    border-radius: 4px 0px 0px 4px;
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0rem 0.5rem;
  }

  .copy-btn {
    font-size: 16px;
    line-height: 24px;
    background-color: #2439a3;
    font-weight: 600;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: white;
    border-radius: 0px 4px 4px 0px;
    padding: 0rem 1.5rem;
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
`;

const AffiliateDashboard = () => {
  const [me, setMe] = React.useState(null);
  const [affiliateData, setAffiliateData] = React.useState(null);
  const { authToken } = React.useContext(AuthCtx);

  React.useEffect(() => {
    const run = async () => {
      const response = await api.get("/me", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      });

      const responseAffiliate = await api.get("/affiliate-stats", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      });

      setMe(response.data);
      setAffiliateData(responseAffiliate.data);
    };

    run();
  }, [authToken]);

  if (!me || !affiliateData) {
    return null;
  }

  const referralUrl = `${window.location.protocol}//${window.location.host}/signup?ref=${me.referral_key}`;

  return (
    <Wrapper>
      <Title>Affiliate Dashboard</Title>
      <CopyUrl>
        <div className="url">{referralUrl}</div>
        <button className="copy-btn">Copy</button>
      </CopyUrl>

      <Stats>
        <Stat>
          <div className="label">All Users</div>
          <div className="value">{affiliateData.referral_count}</div>
        </Stat>

        <Stat>
          <div className="label">Active Users</div>
          <div className="value">{affiliateData.active_referral_count}</div>
        </Stat>
      </Stats>
      <Table>
        <thead className="thead">
          <th>User</th>
          <th>Created</th>
          {me.second_tier_affiliates_enabled && <th>Tier</th>}
          <th>Status</th>
        </thead>

        <tbody>
          {affiliateData.referrals.map((affiliate) => (
            <tr>
              <Td className="strong">{affiliate.full_name}</Td>
              <Td>{moment(affiliate.created_at).fromNow()}</Td>
              {me.second_tier_affiliates_enabled && (
                <Td>{affiliate.is_second_tier ? "2nd tier" : "1st tier"}</Td>
              )}
              <Td className="status">
                {affiliate.is_active ? (
                  <StatusBadge>Active</StatusBadge>
                ) : (
                  <StatusBadge inactive>Inactive</StatusBadge>
                )}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default AffiliateDashboard;
