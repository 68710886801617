/** @jsx jsx */
import qs from "query-string";
import { jsx } from "@emotion/core";
import React from "react";
import { PrimaryButton, Field, Input } from "../components/forms";
import api from "../api";
import pinkCheck from "../assets/pink-check.svg";
import spbLogo from "../assets/spb-logo.png";
import mq from "../components/responsive";

const features = [
  "Friend Finder with Smart Keywords",
  "Message CRM",
  "Group Funnel integration",
  "Automated Message Templates",
  "Private Facebook Group & Training's",
];

const Signup = ({ history, location }) => {
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setLoading(true);

    const referralKey = qs.parse(location.search).ref;

    const [firstName, lastName] = name.split(" ");

    try {
      const response = await api.post("/auth/signup", {
        email,
        password,
        first_name: firstName || "",
        last_name: lastName || "",
        referrer: referralKey,
      });

      window.fbq("track", "CompleteRegistration");
      history.push(`/subscribe?token=${response.data.token}`);
    } catch (err) {
      setErrorMessage(Object.values(err.response.data)[0][0]);
      setLoading(false);
    }
  };

  return (
    <div
      css={{
        display: "flex",
        height: "100vh",
      }}
    >
      <div
        css={mq({
          width: "50vw",
          backgroundColor: "#2439A3",
          display: ["none", "flex"],
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        })}
      >
        <img
          css={{ height: 100, marginBottom: "2.5rem" }}
          src={spbLogo}
          alt="SocialProfitBots"
        />
        <div
          css={{
            marginBottom: "4rem",
            fontSize: 32,
            lineHeight: "40px",
            fontWeight: "600",
          }}
        >
          Try Social Proof Bots for Free!
        </div>

        <div>
          {features.map((feature) => (
            <div
              css={{ marginBottom: "0.5rem", display: "flex" }}
              key={feature}
            >
              <img src={pinkCheck} alt="pink check" />
              <div
                css={{
                  fontSize: "21px",
                  lineHeight: "30px",
                  marginLeft: "12px",
                }}
              >
                {feature}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        css={mq({
          width: ["100%", "50vw"],
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
        })}
      >
        <div
          css={{
            maxWidth: 400,
            width: "100%",
            margin: "0 auto",
            paddingTop: "3rem",
          }}
        >
          <form onSubmit={handleSubmit}>
            <div
              css={mq({
                fontSize: 24,
                lineHeight: "32px",
                fontWeight: "600",
                marginBottom: "1rem",
                textAlign: ["center", "left"],
              })}
            >
              Create your account
            </div>
            <Field label="Full Name">
              <Input
                value={name}
                autoFocus
                onChange={(e) => setName(e.target.value)}
                placeholder="Full Name"
                required
              />
            </Field>

            <Field label="Email">
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                type="email"
              />
            </Field>

            <Field label="Password">
              <Input
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                value={password}
                type="password"
                required
              />
            </Field>

            {errorMessage && (
              <div css={{ color: "#C70000", marginBottom: "0.5rem" }}>
                {errorMessage}
              </div>
            )}

            <PrimaryButton
              style={{ marginTop: "1rem", width: "100%" }}
              disabled={loading}
              type="submit"
            >
              {loading ? "Submitting..." : "Submit"}
            </PrimaryButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
