/** @jsx jsx */
import React, { useContext } from "react";
import { jsx } from "@emotion/core";
import { PrimaryButton, Field, Input } from "../components/forms";
import api from "../api";
import { AuthCtx } from "../App";

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const { login } = useContext(AuthCtx);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setLoading(true);

    try {
      const response = await api.post("/auth/token", {
        username: email,
        password,
      });

      login(response.data.token);
    } catch (err) {
      setErrorMessage(Object.values(err.response.data)[0][0]);
      setLoading(false);
    }
  };

  return (
    <div
      css={{
        width: 400,
        margin: "0 auto",
        paddingTop: "3rem",
      }}
    >
      <form onSubmit={handleSubmit}>
        <div
          css={{
            fontSize: 24,
            lineHeight: "32px",
            fontWeight: "600",
            marginBottom: "1rem",
          }}
        >
          Login
        </div>
        <Field label="Email">
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
            type="email"
          />
        </Field>

        <Field label="Password">
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
            type="password"
          />
        </Field>

        {errorMessage && (
          <div css={{ color: "#C70000", marginBottom: "0.5rem" }}>
            {errorMessage}
          </div>
        )}

        <PrimaryButton
          style={{ marginTop: "1rem", width: "100%" }}
          disabled={loading}
          type="submit"
        >
          {loading ? "Submitting..." : "Submit"}
        </PrimaryButton>
      </form>
    </div>
  );
};

export default Login;
