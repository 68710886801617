/** @jsx jsx */
import { jsx } from "@emotion/core";

const ThankYou = () => {
  return (
    <div
      css={{
        paddingTop: "2rem",
        maxWidth: 600,
        textAlign: "center",
        margin: "0 auto",
        a: {
          color: "#5435C8",
          fontWeight: 500,
          textDecoration: "none",
        },
      }}
    >
      <div
        css={{
          fontSize: 24,
          lineHeight: "32px",
          fontWeight: "600",
          marginBottom: "1rem",
          textAlign: "center",
        }}
      >
        You're all set!
      </div>

      <div css={{ fontSize: 16, lineHeight: "24px" }}>
        You can now start using SocialProfitBot, to continue please{" "}
        <a href="https://socialprofitbots.s3.amazonaws.com/socialprofitbot.zip">
          click here to download
        </a>{" "}
        and{" "}
        <a href="https://www.notion.so/SocialProfitBot-Help-Center-fd304639f14e4381bb02f6035be5d359">
          follow the instructions
        </a>{" "}
        to set it up.
      </div>
    </div>
  );
};

export default ThankYou;
